.footerPanelWrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding-top: 8.161vh;
}
.infoSectionWrapper {
  margin-left: 0.694vw;
  max-width: 34.722vw;
  margin-right: 1.389vw;
  display: flex;
  flex-direction: column;
}
.infoAboutFooter {
  margin-bottom: 3.96vh;
  color: #0d1e42;
  display: flex;
  flex-direction: column;
}
.infoAboutFooter h1 {
  font-family: "Clash Display";
  font-size: 1.667vw;
  font-weight: 500;
}
.formWithResponse {
  margin: 0 auto;
}
.infoAboutFooter span:first-of-type {
  margin: 2.2vh 0;
}
.infoAboutFooter span {
  font-family: "Barlow";
  font-size: 1.25vw;
  line-height: 2.222vw;
}
.textHighlited {
  font-weight: 500;
  color: #ff3c00;
  text-decoration: underline;
}
.setTextColour {
  color: #ff3c00;
}
.setGapAfterInput {
  margin-bottom: 2.64vh;
}
.gmailInstructionsWrapper {
  display: flex;
  justify-content: center;
  align-content: center;
}
.gmailInstructions {
  display: flex;
  flex-direction: column;
  border: 0.069vw solid #7688b0;
  border-radius: 1.042vw;
  padding: 1.65vh 1.042vw;
  gap: 0.55vh;
}
.mailLogoPosition {
  display: flex;
  padding-bottom: 1.1vh;
  border-bottom: 0.069vw solid #7688b0;
}
.mailLogoPosition span {
  margin-left: 0.556vw;
  align-items: center;
  display: flex;
  font-family: "Clash Display";
  font-weight: 500;
  font-size: 1.25vw;
  color: #0d1e42;
}
.instructions {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1.25vw;
  color: #0d1e42;
  padding-left: 1.25vw;
  border-bottom: 0.069vw solid #7688b0;
}
.instructions li {
  margin: 1.1vh 0;
}
.downloadPDF {
  padding-top: 0.55vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.downloadPDF span {
  display: flex;
  align-items: center;
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1.25vw;
  color: #0d1e42;
}
.downloadBtn {
  background-color: #5bc1a1;
  font-size: 1.111vw;
  border-radius: 0.694vw;
  padding: 0.66vh 0.903vw;
  height: unset;
  max-height: 4.4vh;
  color: #0e1d42;
}
.downloadBtn:hover {
  color: #0e1d42;
  text-decoration: unset;
  border-color: #43ab8a;
  transition: 300ms ease-in-out;
  background-color: #43ab8a;
}
.gmailIcon {
  width: 3.125vw;
  max-width: 45px;
  height: auto;
}
.footerForm {
  min-width: 540px;
}
.generatedFooter {
  background-color: #f2f2f2;
  border: 0.139vw solid #5bc1a1;
  padding: 2.083vw;
  display: flex;
  flex-direction: column;
  border-radius: 1.042vw;
}
.generatedFooterTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.1vh;
  font-family: "Clash Display";
  font-size: 1.25vw;
}
.goBackToForm {
  max-width: 11.111vw;
  background-color: #5bc1a1;
  color: #0e1d42;
  border-radius: 0.694vw;
  font-family: "Barlow";
  font-size: 0.972vw;
  cursor: pointer;
  z-index: 1000;
}
.goBackToForm:hover {
  color: #0e1d42 !important;
  text-decoration: unset !important;
  border-color: #43ab8a !important;
  transition: 300ms ease-in-out;
  background-color: #43ab8a;
}

@media all and (max-width: 3840px) and (min-width: 2800px) {
  .infoSectionWrapper {
    margin-left: 0.26vw;
    max-width: 13.021vw;
    margin-right: 0.521vw;
  }
  .infoAboutFooter h1 {
    font-size: 0.625vw;
  }
  .infoAboutFooter span {
    font-size: 0.469vw;
    line-height: 0.833vw;
  }
  .gmailInstructions {
    border: 0.026vw solid #7688b0;
    border-radius: 0.391vw;
    padding: 1.65vh 0.391vw;
  }
  .mailLogoPosition {
    border-bottom: 0.026vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 0.208vw;
    font-size: 0.469vw;
  }
  .instructions {
    font-size: 0.469vw;
    padding-left: 0.469vw;
    border-bottom: 0.026vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 0.469vw;
  }
  .downloadBtn {
    font-size: 0.417vw;
    border-radius: 0.26vw;
    padding: 0.66vh 0.339vw;
  }
  .generatedFooter {
    border: 0.052vw solid #5bc1a1;
    padding: 0.781vw;
    border-radius: 0.391vw;
  }
  .generatedFooterTitle {
    font-size: 0.469vw;
  }
  .goBackToForm {
    max-width: 4.167vw;
    border-radius: 0.26vw;
    font-size: 0.365vw;
  }
}
@media all and (max-width: 2560px) and (min-width: 2000px) {
  .infoSectionWrapper {
    margin-left: 0.391vw;
    max-width: 19.531vw;
    margin-right: 0.781vw;
  }
  .infoAboutFooter h1 {
    font-size: 0.938vw;
  }
  .infoAboutFooter span {
    font-size: 0.703vw;
    line-height: 1.25vw;
  }
  .gmailInstructions {
    border: 0.039vw solid #7688b0;
    border-radius: 0.586vw;
    padding: 1.65vh 0.586vw;
  }
  .mailLogoPosition {
    border-bottom: 0.039vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 0.313vw;
    font-size: 0.703vw;
  }
  .instructions {
    font-size: 0.703vw;
    padding-left: 0.703vw;
    border-bottom: 0.039vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 0.703vw;
  }
  .downloadBtn {
    font-size: 0.625vw;
    border-radius: 0.391vw;
    padding: 0.66vh 0.508vw;
  }
  .generatedFooter {
    border: 0.078vw solid #5bc1a1;
    padding: 1.172vw;
    border-radius: 0.586vw;
  }
  .generatedFooterTitle {
    font-size: 0.703vw;
  }
  .goBackToForm {
    max-width: 6.25vw;
    border-radius: 0.391vw;
    font-size: 0.547vw;
  }
}
@media all and (max-width: 1920px) and (min-width: 1600px) {
  .infoSectionWrapper {
    margin-left: 0.521vw;
    max-width: 26.042vw;
    margin-right: 1.042vw;
  }
  .infoAboutFooter h1 {
    font-size: 1.25vw;
  }
  .infoAboutFooter span {
    font-size: 0.938vw;
    line-height: 1.667vw;
  }
  .gmailInstructions {
    border: 0.052vw solid #7688b0;
    border-radius: 0.781vw;
    padding: 1.65vh 0.781vw;
  }
  .mailLogoPosition {
    border-bottom: 0.052vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 0.417vw;
    font-size: 0.938vw;
  }
  .instructions {
    font-size: 0.938vw;
    padding-left: 0.938vw;
    border-bottom: 0.052vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 0.938vw;
  }
  .downloadBtn {
    font-size: 0.833vw;
    border-radius: 0.521vw;
    padding: 0.66vh 0.677vw;
  }
  .generatedFooter {
    border: 0.104vw solid #5bc1a1;
    padding: 1.563vw;
    border-radius: 0.781vw;
  }
  .generatedFooterTitle {
    font-size: 0.938vw;
  }
  .goBackToForm {
    max-width: 8.333vw;
    border-radius: 0.521vw;
    font-size: 0.729vw;
  }
}
@media all and (max-width: 1280px) {
  .infoSectionWrapper {
    margin-left: 0.781vw;
    max-width: 39.063vw;
    margin-right: 1.563vw;
  }
  .infoAboutFooter h1 {
    font-size: 1.406vw;
  }
  .infoAboutFooter span {
    font-size: 1.406vw;
    line-height: 2.5vw;
  }
  .gmailInstructions {
    border: 0.078vw solid #7688b0;
    border-radius: 1.172vw;
    padding: 1.65vh 1.172vw;
  }
  .mailLogoPosition {
    border-bottom: 0.078vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 0.625vw;
    font-size: 1.406vw;
  }
  .instructions {
    font-size: 1.406vw;
    padding-left: 1.406vw;
    border-bottom: 0.078vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 1.406vw;
  }
  .downloadBtn {
    font-size: 1.25vw;
    border-radius: 0.781vw;
    padding: 0.66vh 1.016vw;
  }
  .generatedFooter {
    border: 0.156vw solid #5bc1a1;
    padding: 2.344vw;
    border-radius: 1.172vw;
  }
  .generatedFooterTitle {
    font-size: 1.406vw;
  }
  .goBackToForm {
    max-width: 12.5vw;
    border-radius: 0.781vw;
    font-size: 1.094vw;
  }
}
@media all and (max-width: 1024px) {
  .footerPanelWrapper {
    flex-direction: column;
    padding-top: 6.161vh;
  }
  .infoSectionWrapper {
    margin: 0 auto;
    max-width: 69.336vw;
  }
  .infoAboutFooter {
    margin-bottom: 4.661vh;
  }
  .infoAboutFooter h1 {
    font-size: 2.344vw;
  }
  .infoAboutFooter span:first-of-type {
    margin-top: 1.56vh;
  }
  .infoAboutFooter span {
    font-size: 1.758vw;
    line-height: 3.125vw;
  }
  .gmailInstructionsWrapper {
    margin-bottom: 3.3vh;
  }
  .gmailInstructions {
    border: 0.098vw solid #7688b0;
    border-radius: 1.465vw;
    padding: 1.65vh 1.465vw;
  }
  .mailLogoPosition {
    border-bottom: 0.098vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 0.781vw;
    font-size: 1.758vw;
  }
  .instructions {
    font-size: 1.758vw;
    padding-left: 1.758vw;
    border-bottom: 0.098vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 1.758vw;
  }
  .downloadBtn {
    font-size: 1.563vw;
    border-radius: 0.977vw;
    padding: 0.66vh 1.27vw;
  }
  .generatedFooter {
    border: 0.195vw solid #5bc1a1;
    padding: 2.93vw;
    border-radius: 1.465vw;
  }
  .generatedFooterTitle {
    font-size: 1.758vw;
  }
  .goBackToForm {
    max-width: 15.625vw;
    border-radius: 0.977vw;
    font-size: 1.367vw;
  }
}
@media all and (max-width: 768px) {
  .footerPanelWrapper {
    padding-top: 3.161vh;
  }
  .infoAboutFooter {
    margin-bottom: 3.661vh;
  }
  .infoAboutFooter h1 {
    font-size: 3.125vw;
  }
  .infoAboutFooter span:first-of-type {
    margin-top: 1.26vh;
  }
  .infoAboutFooter span {
    font-size: 2.344vw;
    line-height: 4.167vw;
  }
  .gmailInstructions {
    border: 0.13vw solid #7688b0;
    border-radius: 1.953vw;
    padding: 1.65vh 1.953vw;
  }
  .mailLogoPosition {
    border-bottom: 0.13vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 1.042vw;
    font-size: 2.344vw;
  }
  .instructions {
    font-size: 2.344vw;
    padding-left: 2.344vw;
    border-bottom: 0.13vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 2.344vw;
  }
  .downloadBtn {
    font-size: 1.563vw;
    border-radius: 1.302vw;
    padding: 0.66vh 1.27vw;
  }
  .generatedFooter {
    border: 0.195vw solid #5bc1a1;
    padding: 2.93vw;
    border-radius: 1.953vw;
  }
  .generatedFooterTitle {
    font-size: 2.344vw;
  }
  .goBackToForm {
    max-width: 20.833vw;
    border-radius: 1.302vw;
    font-size: 1.823vw;
  }
}
@media all and (max-width: 480px) {
  .infoAboutFooter {
    margin-bottom: 3.661vh;
  }
  .infoAboutFooter h1 {
    margin-left: 15vw;
    font-size: 4.167vw;
  }
  .infoAboutFooter span:first-of-type {
    text-indent: 15vw;
  }
  .infoAboutFooter span {
    font-size: 3.333vw;
    line-height: 4.667vw;
  }
  .gmailInstructions {
    border: 0.208vw solid #7688b0;
    border-radius: 3.125vw;
    padding: 1.65vh 3.125vw;
  }
  .mailLogoPosition {
    border-bottom: 0.208vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 1.667vw;
    font-size: 3.75vw;
  }
  .instructions {
    font-size: 3.333vw;
    padding-left: 3.75vw;
    border-bottom: 0.208vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 3.333vw;
  }
  .downloadBtn {
    font-size: 2.5vw;
    border-radius: 1.302vw;
    padding: 0.66vh 1.27vw;
  }
  .generatedFooter {
    border: 0.195vw solid #5bc1a1;
    padding: 2.917vw;
    border-radius: 3.125vw;
  }
  .generatedFooterTitle {
    font-size: 3.75vw;
  }
  .goBackToForm {
    max-width: 27.083vw;
    border-radius: 1.302vw;
    font-size: 2.917vw;
  }
  .gmailIcon {
    width: 5.5vw;
  }
}
@media all and (max-width: 400px) {
  .infoAboutFooter {
    margin-bottom: 2.661vh;
  }
  .infoAboutFooter h1 {
    margin-left: 20vw;
    font-size: 4vw;
  }
  .infoAboutFooter span:first-of-type {
    text-indent: 20vw;
  }
  .infoAboutFooter span {
    font-size: 3.5vw;
    line-height: 5.5vw;
  }
  .gmailInstructions {
    border: 0.25vw solid #7688b0;
    border-radius: 3.75vw;
    padding: 1.65vh 3.75vw;
  }
  .mailLogoPosition {
    border-bottom: 0.25vw solid #7688b0;
  }
  .mailLogoPosition span {
    margin-left: 2vw;
    font-size: 3.75vw;
  }
  .instructions {
    font-size: 3.5vw;
    padding-left: 3.75vw;
    border-bottom: 0.25vw solid #7688b0;
  }
  .downloadPDF span {
    font-size: 3.5vw;
  }
  .downloadBtn {
    font-size: 3vw;
    border-radius: 2vw;
    padding: 0.66vh 1.27vw;
  }
  .generatedFooter {
    border: 0.195vw solid #5bc1a1;
    padding: 2.917vw;
    border-radius: 3.75vw;
  }
  .generatedFooterTitle {
    font-size: 4.5vw;
  }
  .goBackToForm {
    max-width: 38.5vw;
    border-radius: 2vw;
    font-size: 3.5vw;
  }
  .gmailIcon {
    width: 6vw;
  }
}
