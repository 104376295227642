* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "Clash Display";
  src: url("./components/fonts/ClashDisplay-Variable.ttf");
}
@font-face {
  font-family: "Barlow";
  src: url("./components/fonts/Barlow-Regular.ttf");
}
.ant-layout-content {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
}

.ant-layout {
  min-height: 100vh;
  background-color: #e8e8eb;
}

.site-layout-background {
  min-height: 100vh;
  overflow: auto;
}
.menuCon {
  min-height: 100vh;
}

@media all and (max-width: 900px) {
  .menuCon {
    position: absolute;
  }
  .site-layout-background {
    /* /////////////////////////CHECK IF WORKEEEEEE!!!!!!!!!!!!!///// */
    display: flex;
    /* display: grid; */
    justify-content: center;
    align-items: center;
  }
}
@media all and (max-width: 480px) {
  .site-layout-background {
    min-height: 100vh;
    overflow: visible;
    flex: 1;
  }
}
