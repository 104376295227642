.licensesWrapper {
  display: flex;
  flex-direction: column;
  margin: 0 1.525rem;
  padding: 9vh 0 2.64vh 0;
  min-height: 100vh;
  color: #0d1e42;
}
.licenceHeader {
  font-family: "Clash Display";
  font-weight: 500;
  font-size: 1.667vw;
  margin-bottom: 0.99vh;
}
.licenceInfo {
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1.25vw;
  margin-bottom: 2.2vh;
}
.licence {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4.4vh;
}
.licence .timer {
  width: 34.722vw;
}
.searchBarWrapper {
  width: 30.694vw;
  height: 4.07vh;
  margin-bottom: 5.611vh;
  background-color: #d9d9d9;
  /* 25px */
  border-radius: 1.736vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.searchSVG {
  margin-left: 10px;
}
.ant-input-affix-wrapper {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  background-color: unset !important;
  border: unset !important;
  box-shadow: unset !important;
}
.ant-input-affix-wrapper:focus {
  box-shadow: unset !important;
}
.searchBarWrapper .ant-input {
  background-color: unset;
}
.searchBarWrapper .ant-input:focus {
  border-color: unset !important;
  border: none !important;
  box-shadow: none !important;
}
.ant-input-group-addon {
  display: none !important;
}
.ant-input::placeholder {
  color: #888888;
}
.licence .ant-statistic-content {
  /* 26px */
  font-size: 1.806vw !important;
}
.licence .ant-statistic-title {
  font-size: 1.389vw !important;
}

@media all and (max-width: 3840px) and (min-width: 2561px) {
  .licenceHeader {
    font-size: 0.625vw;
  }
  .licenceInfo {
    font-size: 0.469vw;
  }
  .searchBarWrapper {
    border-radius: 0.651vw;
    width: 11.51vw;
  }
  .licence .timer {
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.391vw;
    width: 13.021vw;
  }
  .licence .ant-statistic-content {
    font-size: 0.677vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 0.521vw !important;
  }
}
@media all and (max-width: 2560px) and (min-width: 1921px) {
  .licenceHeader {
    font-size: 0.938vw;
  }
  .licenceInfo {
    font-size: 0.703vw;
  }
  .searchBarWrapper {
    border-radius: 0.977vw;
    width: 17.265vw;
  }
  .licence .timer {
    width: 19.531vw;
    border: 0.078vw solid #5bc1a1;
    border-radius: 0.586vw;
  }
  .licence .ant-statistic-content {
    font-size: 1.016vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 0.781vw !important;
  }
}
@media all and (max-width: 1920px) and (min-width: 1600px) {
  .licenceHeader {
    font-size: 1.25vw;
  }
  .licenceInfo {
    font-size: 0.938vw;
  }
  .searchBarWrapper {
    border-radius: 1.302vw;
    width: 23.021vw;
  }
  .licence .timer {
    width: 26.042vw;
  }
  .licence .ant-statistic-content {
    font-size: 1.354vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 1.042vw !important;
  }
}
@media all and (max-width: 1280px) {
  .licenceHeader {
    font-size: 1.875vw;
  }
  .licenceInfo {
    font-size: 1.406vw;
  }
  .searchBarWrapper {
    border-radius: 1.953vw;
    width: 34.531vw;
  }
  .licence .timer {
    width: 39.063vw;
  }
  .licence .ant-statistic-content {
    font-size: 2.031vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 1.563vw !important;
  }
}
@media all and (max-width: 1024px) {
  .licenceHeader {
    font-size: 2.344vw;
  }
  .licenceInfo {
    font-size: 1.758vw;
  }
  .searchBarWrapper {
    border-radius: 2.441vw;
    width: 43.163vw;
  }
  .licence .timer {
    width: 48.828vw;
  }
  .licence .ant-statistic-content {
    font-size: 2.539vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 1.953vw !important;
  }
}
@media all and (max-width: 768px) {
  .licensesWrapper {
    padding: 30px 0 4.64vh 0;
  }
  .licenceHeader {
    font-size: 3.125vw;
  }
  .licenceInfo {
    font-size: 2.344vw;
  }
  .searchBarWrapper {
    border-radius: 3.255vw;
    width: 57.551vw;
  }
  .licence .timer {
    width: 65.104vw;
    border: 0.26vw solid #5bc1a1;
    border-radius: 1.953vw;
  }
  .licence .ant-statistic-content {
    font-size: 3.385vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 2.8vw !important;
  }
  .licence .translated {
    transform: translate(-0.39vh, 0.8vw) !important;
  }
}
@media all and (max-width: 480px) {
  .licence {
    align-items: center;
    gap: 3.3vh;
  }
  .licensesWrapper {
    padding: 30px 0 2.64vh 0;
  }
  .licenceHeader {
    font-size: 4.167vw;
    margin-left: 18vw;
  }
  .licenceInfo {
    font-size: 3.333vw;
    margin-left: 18vw;
  }
  .searchBarWrapper {
    border-radius: 5.208vw;
    width: 92.846vw;
    margin-bottom: 4vh;
  }
  .licence .timer {
    width: 92.846vw;
    height: 6.501vh;
    border: 0.417vw solid #5bc1a1;
    border-radius: 2.083vw;
  }
  .licence .ant-statistic-content {
    font-size: 4.75vw !important;
  }
  .licence .ant-statistic-title {
    font-size: 3.917vw !important;
  }
}
@media all and (max-width: 400px) {
  .licenceHeader {
    font-size: 4vw;
  }
  .licenceInfo {
    font-size: 3.5vw;
  }
  .searchBarWrapper {
    border-radius: 6.25vw;
    width: 91.5vw;
  }
  .licence .timer {
    width: 89vw;
    height: 5.501vh;
  }
  .licence .ant-statistic-content {
    font-size: 4.5vw !important;
  }
}
@media all and (max-width: 375px) {
  .licenceHeader {
    font-size: 4.267vw;
    margin-left: 22vw;
  }
  .licenceInfo {
    font-size: 3.733vw;
    margin-left: 22vw;
  }
  .searchBarWrapper {
    border-radius: 6.667vw;
    width: 97.6vw;
  }
  .licence .timer {
    width: 94.933vw;
    height: 5vh;
  }
  .licence .ant-statistic-content {
    font-size: 4.8vw !important;
  }
}
