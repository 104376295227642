.navbar-option {
  min-height: 100vh;
  position: sticky;
  top: 0;
}
.position-unset {
  position: unset;
}
[data-theme="dark"],
.navbar-option {
  background: #0d1e42;
}
.website {
  text-align: center;
}
.website ul {
  margin: 20px 0;
}
.website ul li {
  padding: 30px 0;
}
.ant-menu-dark,
.ant-layout-sider {
  background: #0d1e42 !important;
}
.logo {
  display: flex;
  height: 48px;
  margin: 28px 19px 0px 25px;
  color: white;
  font-size: 15px;
  cursor: pointer;
}
.navbar-toggle {
  position: relative;
  padding-top: 8px;
}
.menu-cross {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  color: rgba(255, 255, 255, 0.8);
}
.xBtn {
  height: 20px;
  width: auto;
}
.nav-menu.active .logo {
  margin: 10px 19px 0px 25px;
}
.anticon-close {
  scale: 1.4;
}
.anticon-close:hover {
  color: white;
}
.nav-menu {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  background-color: #0d1e42;
  z-index: 1000;
  min-height: 100vh;
}
.nav-menu.active {
  left: 0;
  transition: 350ms;
  background-color: #0d1e42;
  z-index: 1000;
  min-height: calc(100vh - 27px);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.logo-mobile {
  display: flex;
  height: 48px;
  margin: 16px 10px 16px 10px;
  color: white;
  font-size: large;
}
.ant-btn.ant-btn-primary.barsMenu {
  display: block;
  border: none;
  width: 70px;
  height: 70px;
  box-shadow: unset !important;
  border-radius: 50px;
  outline: unset !important;
  outline-offset: 0;
  transition: unset !important;
  margin: 6px;
  padding: 0;
  z-index: 1000;
}
.barsMenu .iconMobile {
  padding: 0;
  /* background-color: #e8e8eb; */
}
.ant-menu-item-selected,
.ant-menu-item-active {
  background-color: #0d1e42 !important;
}
.ant-menu-item {
  padding-left: 0px;
  align-items: center;
}
.pointer-transition {
  margin-bottom: 10px;
  margin-left: -15px;
  transition: fill 0.3s ease; /* Smooth transition for the fill color */
}
.ant-menu-title-content {
  color: #fff;
  font-family: "Clash Display";
  font-size: 16px;
  margin-inline-start: 3px;
  /* line-height: 90px; */
}
#change-navi-bg {
  background-color: #0d1e42;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-layout-sider {
  width: unset !important;
  max-width: 222px !important;
  min-width: 200px !important;
  min-height: 100vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
}
.nav-menu.active .ant-layout-sider {
  min-height: calc(100vh - 27px);
}
.ant-layout-sider-children {
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
}
.footerBasic {
  color: #fff;
  font-size: 12px;
  font-family: "Barlow";
  font-weight: 400;
  text-align: center;
  position: absolute;
  bottom: 11px;
  left: 0;
  right: 0;
}
.logoutBtn {
  position: absolute;
  bottom: 40px;
  left: 14px;
  right: 0;
  text-decoration: none;
  border: unset;
  border-radius: 10px;
  background-color: #5bc1a1;
  text-align: center;
  font-family: "Clash Display";
  font-size: 18px;
  font-weight: 500;
  color: #0d1e42;
  padding: 10px 48px;
  cursor: pointer;

  background: linear-gradient(to left, #5bc1a1 50%, #43ab8a 50%) right;
  background-size: 200% 100%;
  transition: background-position 0.5s ease-in-out;
}
.logoutBtn:hover {
  background-position: left;
}
.mobileViewBtn {
  bottom: 40px;
}
.mobileViewFtr {
  bottom: 10px;
}
@media all and (max-width: 900px) {
  #change-navi-bg {
    background-color: #e8e8eb;
  }
}
@media all and (max-width: 480px) {
  #change-navi-bg {
    background-color: unset;
  }
}
