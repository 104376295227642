.offersPanelWrapper {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  padding-top: 8.161vh;
}
.infoSectionWrapper {
  margin-left: 0.694vw;
  max-width: 34.722vw;
  margin-right: 1.389vw;
}
.infoAboutOffersGen {
  margin-bottom: 11.661vh;
  color: #0d1e42;
  display: flex;
  flex-direction: column;
}
.infoAboutOffersGen h1,
.sendOfferInfo h1 {
  font-family: "Clash Display";
  font-size: 1.667vw;
  font-weight: 500;
}
.formWithResponse {
  margin: 0 auto;
}
.infoAboutOffersGen span:first-of-type {
  margin-top: 3.96vh;
}
.infoAboutOffersGen span,
.sendOfferInfo span {
  font-family: "Barlow";
  font-size: 1.25vw;
  line-height: 2.222vw;
}
.textHighlited,
.textHighlited:hover {
  font-weight: 500;
  color: #ff3c00;
  text-decoration: underline;
}
.sendOfferInfo {
  display: flex;
  flex-direction: column;
  color: #0d1e42;
}
.sendOfferInfo span:first-of-type {
  margin-top: 0.99vh;
}
.formOutlineWrapper {
  background-color: #4f5b75;
  border-radius: 1.042vw;
  padding: 1.597vw;
  position: relative;
  width: 49.306vw;
  min-width: 630px;
}
.offerForm {
  background-color: #f2f2f2;
  border: 0.139vw solid #5bc1a1;
  border-radius: 1.042vw;
  padding-left: 1.389vw;
  padding-right: 0.903vw;
  overflow-y: auto;
  max-height: 86.909vh;
  scrollbar-width: thin;
  scrollbar-color: #6b918b #b3b3b3;
}
/* width */
::-webkit-scrollbar {
  width: 0.694vw;
}
/* Track */
::-webkit-scrollbar-track {
  background: #b3b3b3;
  border-top-right-radius: 1.042vw;
  border-bottom-right-radius: 1.042vw;
  border-top-left-radius: 0.347vw;
  border-bottom-left-radius: 0.347vw;
  /* border-radius: 5px; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b918b;
  /* border-radius: 5px; */
  border-top-right-radius: 1.042vw;
  border-bottom-right-radius: 1.042vw;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #648782;
}
.clearAllFieldsBtn {
  margin-top: 1.87vh;
  background-color: #fb7349;
  color: #0e1d42;
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1.111vw;
  border-top-left-radius: 1.042vw;
  border-bottom-right-radius: 1.042vw;
  height: unset;
  float: right;
  text-decoration: unset !important;
  text-align: center;
}
.clearAllFieldsBtn:hover {
  color: #0e1d42 !important;
  text-decoration: unset !important;
  border-color: #ee673d !important;
  transition: 300ms ease-in-out;
  background-color: #ee673d;
}
.ant-divider {
  font-size: 1.25vw !important;
  font-family: "Barlow";
  font-weight: 400 !important;
  border-bottom: 0.069vw solid #5bc1a1;
}
.ant-divider::after {
  border-block-start: unset !important;
}
.info {
  font-size: 0.972vw;
}
.ant-radio-button-wrapper {
  background-color: #d3e2e0;
  border: unset;
}
.ant-radio-group-outline {
  border: 0.069vw solid #5bc1a1;
  border-radius: 0.694vw;
}
.ant-radio-button-wrapper:first-child {
  border-inline-start: unset;
  border-start-start-radius: 0.694vw;
  border-end-start-radius: 0.694vw;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #5bc1a1 !important;
}
.ant-radio-button-wrapper:last-child {
  border-start-end-radius: 0.694vw;
  border-end-end-radius: 0.694vw;
}
.ant-radio-button-wrapper:hover,
.ant-radio-button-wrapper-checked {
  color: #000 !important;
  background-color: #9bd2c2;
  background: #9bd2c2 !important;
}
.ant-input {
  border: 0.069vw solid #5bc1a1;
  border-radius: 0.694vw;
  height: 3.85vh;
  /* max-width: 400px; */
  background-color: #fff;
}
.ant-input:hover {
  border: 0.069vw solid #5bc1a1;
}
.ant-input:focus {
  border-color: #5bc1a1 !important;
  background-color: #fff;
}
.gridWrapper {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: auto;
}
.gridSpacing {
  margin-left: 1rem !important;
}
.ant-btn-dashed,
.ant-btn-dashed:hover {
  border-color: #5bc1a1 !important;
  color: #ff3c00 !important;
}
.ant-btn-dashed:hover {
  background-color: #e7e7e7;
}
.submitOfferBtn {
  background-color: #fb7349;
  border-radius: 0.694vw;
  color: #0e1d42;
  font-size: 1.111vw;
  font-family: "Barlow";
  height: 4.4vh;
}
.submitOfferBtn:hover {
  color: #0e1d42 !important;
  text-decoration: unset !important;
  border-color: #ee673d !important;
  transition: 300ms ease-in-out;
  background-color: #ee673d;
}
.single-product-placing {
  display: flex;
  flex-wrap: wrap;
}
.single-product-placing > div {
  flex: 48%;
}
.single-product {
  border: 0.069vw solid #5bc1a1;
  border-radius: 1.042vw;
  background-color: #d3e2e0;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  display: flex;
  flex-direction: column;
}
.remove-single-item {
  height: 20px;
  width: auto;
  margin: 0;
  align-self: self-end;
  margin-bottom: 1.32vh;
  cursor: pointer;
}
.upload-file-button {
  border-color: #4e877e;
  background-color: #f2f2f2;
  color: #ff3c00;
  border-style: dashed !important;
}
.upload-file-button.ant-btn-default:hover {
  border-color: #4e877e;
  background-color: #e8e8eb;
  color: #ff3c00;
}
.ant-upload-list-item {
  border: 0.069vw solid #5bc1a1 !important;
}
.anticon-delete {
  color: #ff3c00 !important;
}
.ant-form-item-required {
  width: 9.028vw !important;
}
.offer-btn {
  margin-top: 1rem;
  width: 100%;
}
.add-product {
  display: block;
  flex: 100% !important;
}
.err-message {
  color: #ff3c00;
  margin-left: 10px;
}
.wrapperDecoration {
  position: absolute;
  height: 7.261vh;
  z-index: 100;
}
@media all and (max-width: 3840px) and (min-width: 2800px) {
  .infoSectionWrapper {
    margin-left: 0.26vw;
    max-width: 13.021vw;
    margin-right: 0.521vw;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 0.625vw;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 0.469vw;
    line-height: 0.833vw;
  }
  .formOutlineWrapper {
    border-radius: 0.391vw;
    padding: 0.599vw;
    width: 18.49vw;
  }
  .offerForm {
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.391vw;
    padding-left: 0.26vw;
    padding-right: 0.339vw;
  }
  ::-webkit-scrollbar {
    width: 0.26vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 0.391vw;
    border-bottom-right-radius: 0.391vw;
    border-top-left-radius: 0.26vw;
    border-bottom-left-radius: 0.26vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 0.391vw;
    border-bottom-right-radius: 0.391vw;
  }
  .clearAllFieldsBtn {
    font-size: 0.417vw;
    border-top-left-radius: 0.391vw;
    border-bottom-right-radius: 0.391vw;
  }
  .ant-divider {
    font-size: 0.469vw !important;
    border-bottom: 0.026vw solid #5bc1a1;
  }
  .info {
    font-size: 0.365vw;
  }
  .ant-radio-group-outline {
    border: 0.026vw solid #5bc1a1;
    border-radius: 0.26vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0.26vw;
    border-end-start-radius: 0.26vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0.26vw;
    border-end-end-radius: 0.26vw;
  }
  .ant-input {
    border: 0.026vw solid #5bc1a1;
    border-radius: 0.26vw;
  }
  .ant-input:hover {
    border: 0.026vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 0.26vw;
    font-size: 0.417vw;
  }
  .single-product {
    border: 0.026vw solid #5bc1a1;
    border-radius: 0.391vw;
  }
  .ant-upload-list-item {
    border: 0.026vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 3.385vw !important;
  }
}
@media all and (max-width: 2560px) and (min-width: 2000px) {
  .infoSectionWrapper {
    margin-left: 0.391vw;
    max-width: 19.531vw;
    margin-right: 0.781vw;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 0.938vw;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 0.703vw;
    line-height: 1.25vw;
  }
  .formOutlineWrapper {
    border-radius: 0.586vw;
    padding: 0.898vw;
    width: 27.734vw;
  }
  .offerForm {
    border: 0.078vw solid #5bc1a1;
    border-radius: 0.586vw;
    padding-left: 0.391vw;
    padding-right: 0.508vw;
  }
  ::-webkit-scrollbar {
    width: 0.391vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 0.586vw;
    border-bottom-right-radius: 0.586vw;
    border-top-left-radius: 0.26vw;
    border-bottom-left-radius: 0.26vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 0.586vw;
    border-bottom-right-radius: 0.586vw;
  }
  .clearAllFieldsBtn {
    font-size: 0.625vw;
    border-top-left-radius: 0.586vw;
    border-bottom-right-radius: 0.586vw;
  }
  .ant-divider {
    font-size: 0.703vw !important;
    border-bottom: 0.039vw solid #5bc1a1;
  }
  .info {
    font-size: 0.547vw;
  }
  .ant-radio-group-outline {
    border: 0.039vw solid #5bc1a1;
    border-radius: 0.391vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0.391vw;
    border-end-start-radius: 0.391vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0.391vw;
    border-end-end-radius: 0.391vw;
  }
  .ant-input {
    border: 0.039vw solid #5bc1a1;
    border-radius: 0.391vw;
  }
  .ant-input:hover {
    border: 0.039vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 0.391vw;
    font-size: 0.625vw;
  }
  .single-product {
    border: 0.039vw solid #5bc1a1;
    border-radius: 0.586vw;
  }
  .ant-upload-list-item {
    border: 0.039vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 5.078vw !important;
  }
}
@media all and (max-width: 1920px) and (min-width: 1600px) {
  .infoSectionWrapper {
    margin-left: 0.521vw;
    max-width: 26.042vw;
    margin-right: 1.042vw;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 1.25vw;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 0.938vw;
    line-height: 1.667vw;
  }
  .formOutlineWrapper {
    border-radius: 0.781vw;
    padding: 1.198vw;
    width: 36.979vw;
  }
  .offerForm {
    border: 0.104vw solid #5bc1a1;
    border-radius: 0.781vw;
    padding-left: 1.042vw;
    padding-right: 0.677vw;
  }
  ::-webkit-scrollbar {
    width: 0.521vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 0.781vw;
    border-bottom-right-radius: 0.781vw;
    border-top-left-radius: 0.26vw;
    border-bottom-left-radius: 0.26vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 0.781vw;
    border-bottom-right-radius: 0.781vw;
  }
  .clearAllFieldsBtn {
    font-size: 0.833vw;
    border-top-left-radius: 0.781vw;
    border-bottom-right-radius: 0.781vw;
  }
  .ant-divider {
    font-size: 0.938vw !important;
    border-bottom: 0.052vw solid #5bc1a1;
  }
  .info {
    font-size: 0.729vw;
  }
  .ant-radio-group-outline {
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.521vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0.521vw;
    border-end-start-radius: 0.521vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0.521vw;
    border-end-end-radius: 0.521vw;
  }
  .ant-input {
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.521vw;
  }
  .ant-input:hover {
    border: 0.052vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 0.521vw;
    font-size: 0.833vw;
  }
  .single-product {
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.781vw;
  }
  .ant-upload-list-item {
    border: 0.052vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 6.771vw !important;
  }
}
@media all and (max-width: 1280px) {
  .infoSectionWrapper {
    margin-left: 0.781vw;
    max-width: 39.063vw;
    margin-right: 1.563vw;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 1.406vw;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 1.406vw;
    line-height: 2.5vw;
  }
  .formOutlineWrapper {
    border-radius: 1.172vw;
    padding: 1.797vw;
    /* width: 55.469vw; */
    width: unset;
  }
  .offerForm {
    border: 0.156vw solid #5bc1a1;
    border-radius: 1.172vw;
    padding-left: 1.563vw;
    padding-right: 1.016vw;
  }
  ::-webkit-scrollbar {
    width: 0.781vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 1.172vw;
    border-bottom-right-radius: 1.172vw;
    border-top-left-radius: 0.391vw;
    border-bottom-left-radius: 0.391vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 1.172vw;
    border-bottom-right-radius: 1.172vw;
  }
  .clearAllFieldsBtn {
    font-size: 1.25vw;
    border-top-left-radius: 1.172vw;
    border-bottom-right-radius: 1.172vw;
  }
  .ant-divider {
    font-size: 1.406vw !important;
    border-bottom: 0.078vw solid #5bc1a1;
  }
  .info {
    font-size: 1.094vw;
  }
  .ant-radio-group-outline {
    border: 0.078vw solid #5bc1a1;
    border-radius: 0.781vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0.781vw;
    border-end-start-radius: 0.781vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0.781vw;
    border-end-end-radius: 0.781vw;
  }
  .ant-input {
    border: 0.078vw solid #5bc1a1;
    border-radius: 0.781vw;
  }
  .ant-input:hover {
    border: 0.078vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 0.781vw;
    font-size: 1.25vw;
  }
  .single-product {
    border: 0.078vw solid #5bc1a1;
    border-radius: 1.172vw;
  }
  .ant-upload-list-item {
    border: 0.078vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 10.156vw !important;
  }
}
@media all and (max-width: 1024px) {
  .offersPanelWrapper {
    flex-direction: column;
    padding-top: 6.161vh;
  }
  .infoSectionWrapper {
    margin: 0 auto;
    max-width: 69.336vw;
  }
  .infoAboutOffersGen {
    margin-bottom: 4.661vh;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 2.344vw;
  }
  .infoAboutOffersGen span:first-of-type {
    margin-top: 1.56vh;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 1.758vw;
    line-height: 3.125vw;
  }
  .sendOfferInfo {
    margin-bottom: 2vh;
  }
  .formOutlineWrapper {
    border-radius: 1.465vw;
    padding: 2.246vw;
    width: 69.336vw;
    margin-bottom: 2vh;
  }
  .offerForm {
    border: 0.195vw solid #5bc1a1;
    border-radius: 1.465vw;
    padding-left: 1.953vw;
    padding-right: 1.27vw;
  }
  ::-webkit-scrollbar {
    width: 0.977vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 1.465vw;
    border-bottom-right-radius: 1.465vw;
    border-top-left-radius: 0.488vw;
    border-bottom-left-radius: 0.488vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 1.465vw;
    border-bottom-right-radius: 1.465vw;
  }
  .clearAllFieldsBtn {
    font-size: 1.563vw;
    border-top-left-radius: 1.465vw;
    border-bottom-right-radius: 1.465vw;
  }
  .ant-divider {
    font-size: 1.758vw !important;
    border-bottom: 0.098vw solid #5bc1a1;
  }
  .info {
    font-size: 1.367vw;
  }
  .ant-radio-group-outline {
    border: 0.098vw solid #5bc1a1;
    border-radius: 0.977vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 0.977vw;
    border-end-start-radius: 0.977vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 0.977vw;
    border-end-end-radius: 0.977vw;
  }
  .ant-input {
    border: 0.098vw solid #5bc1a1;
    border-radius: 0.977vw;
    height: 35px;
  }
  .ant-input:hover {
    border: 0.098vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 0.977vw;
    font-size: 1.563vw;
  }
  .single-product {
    border: 0.098vw solid #5bc1a1;
    border-radius: 1.465vw;
  }
  .ant-upload-list-item {
    border: 0.098vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 12.695vw !important;
  }
}
@media all and (max-width: 768px) {
  .offersPanelWrapper {
    padding-top: 4.4vh;
  }
  .infoSectionWrapper {
    max-width: 73.958vw;
  }
  .infoAboutOffersGen {
    margin-bottom: 2.661vh;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 3.125vw;
  }
  .infoAboutOffersGen span:first-of-type {
    margin-top: 1vh;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 2.344vw;
    line-height: 4.167vw;
  }
  .sendOfferInfo {
    margin-bottom: 2vh;
  }
  .formOutlineWrapper {
    border-radius: 1.953vw;
    padding: 2.995vw;
    width: 73.958vw;
  }
  .offerForm {
    border: 0.26vw solid #5bc1a1;
    border-radius: 1.953vw;
    padding-left: 2.604vw;
    padding-right: 1.693vw;
  }
  ::-webkit-scrollbar {
    width: 1.302vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 1.953vw;
    border-bottom-right-radius: 1.953vw;
    border-top-left-radius: 0.651vw;
    border-bottom-left-radius: 0.651vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 1.953vw;
    border-bottom-right-radius: 1.953vw;
  }
  .clearAllFieldsBtn {
    font-size: 2.083vw;
    border-top-left-radius: 1.953vw;
    border-bottom-right-radius: 1.953vw;
  }
  .ant-divider {
    font-size: 2.344vw !important;
    border-bottom: 0.13vw solid #5bc1a1;
  }
  .info,
  .err-message {
    font-size: 1.823vw;
  }
  .ant-radio-group-outline {
    border: 0.13vw solid #5bc1a1;
    border-radius: 1.302vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 1.302vw;
    border-end-start-radius: 1.302vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 1.302vw;
    border-end-end-radius: 1.302vw;
  }
  .ant-input {
    border: 0.13vw solid #5bc1a1;
    border-radius: 1.302vw;
    height: 35px;
  }
  .ant-input:hover {
    border: 0.13vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 1.302vw;
    font-size: 2.083vw;
  }
  .single-product {
    border: 0.13vw solid #5bc1a1;
    border-radius: 1.953vw;
  }
  .ant-upload-list-item {
    border: 0.13vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 16.927vw !important;
  }
}
@media all and (max-width: 480px) {
  .offersPanelWrapper {
    padding-top: 3.3vh;
  }
  .infoSectionWrapper {
    max-width: 118.333vw;
  }
  .infoAboutOffersGen {
    padding-left: 15.167vw;
    margin-bottom: 1.661vh;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 4.167vw;
  }
  .infoAboutOffersGen span:first-of-type {
    margin-top: 0.8vh;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 3.333vw;
    line-height: 4.667vw;
  }
  .sendOfferInfo {
    margin-bottom: 3vh;
  }
  .formOutlineWrapper {
    border-radius: 3.125vw;
    padding: 2.5vw;
    width: 92.475vw;
    min-width: unset;
    margin-bottom: 3.74vh;
  }
  .offerForm {
    border: 0.416vw solid #5bc1a1;
    border-radius: 3.125vw;
    padding-left: 2.292vw;
    padding-right: 2.708vw;
  }
  ::-webkit-scrollbar {
    width: 2.083vw;
  }
  ::-webkit-scrollbar-track {
    border-top-right-radius: 3.125vw;
    border-bottom-right-radius: 3.125vw;
    border-top-left-radius: 1.042vw;
    border-bottom-left-radius: 1.042vw;
  }
  ::-webkit-scrollbar-thumb {
    border-top-right-radius: 3.125vw;
    border-bottom-right-radius: 3.125vw;
  }
  .clearAllFieldsBtn {
    font-size: 2.5vw;
    border-top-left-radius: 3.125vw;
    border-bottom-right-radius: 3.125vw;
  }
  .ant-divider {
    font-size: 3.333vw !important;
    border-bottom: 0.208vw solid #5bc1a1;
    margin: 20px 0 5px 0 !important;
  }
  .info,
  .err-message {
    font-size: 2.5vw;
  }
  .info br {
    display: none;
  }
  .ant-radio-group-outline {
    border: 0.208vw solid #5bc1a1;
    border-radius: 3.333vw;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 3.083vw;
    border-end-start-radius: 3.083vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 3.083vw;
    border-end-end-radius: 3.083vw;
  }
  .ant-input {
    border: 0.208vw solid #5bc1a1;
    border-radius: 3.333vw;
    height: 30px;
  }
  .ant-input:hover {
    border: 0.208vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 2.667vw;
    font-size: 2.917vw;
  }
  .single-product {
    border: 0.208vw solid #5bc1a1;
    border-radius: 3.125vw;
  }
  .ant-form-item-label {
    padding: 0 !important;
  }
  .ant-form-item-label > label {
    font-size: 2.5vw !important;
  }
  .ant-upload-list-item {
    border: 0.208vw solid #5bc1a1 !important;
  }
  .ant-form-item-required {
    width: 43.333vw !important;
  }
  .gridWrapper {
    display: unset;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
  .gridSpacing {
    margin-left: unset !important;
  }
  .add-product {
    margin-bottom: 10px;
  }
  .wrapperDecoration {
    position: absolute;
    height: 4.4vh;
  }
  .err-message {
    margin-top: 5px;
  }
}
@media all and (max-width: 400px) {
  .infoAboutOffersGen {
    padding-left: 16.167vw;
    margin-bottom: 1.661vh;
  }
  .infoAboutOffersGen h1,
  .sendOfferInfo h1 {
    font-size: 4vw;
  }
  .infoAboutOffersGen span,
  .sendOfferInfo span {
    font-size: 3.5vw;
    line-height: 5.5vw;
  }
  .formOutlineWrapper {
    border-radius: 3.75vw;
    padding: 3vw;
  }
  .offerForm {
    border: 0.5vw solid #5bc1a1;
    border-radius: 3.75vw;
    padding-left: 2.75vw;
    padding-right: 3.25vw;
  }
  .clearAllFieldsBtn {
    font-size: 3vw;
    border-top-left-radius: 3.75vw;
    border-bottom-right-radius: 3.75vw;
  }
  .ant-divider {
    font-size: 4vw !important;
    border-bottom: 0.25vw solid #5bc1a1;
  }
  .info,
  .err-message {
    font-size: 3vw;
  }
  .ant-radio-group-outline {
    border: 0.25vw solid #5bc1a1;
  }
  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 3.5vw;
    border-end-start-radius: 3.5vw;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 3.5vw;
    border-end-end-radius: 3.5vw;
  }
  .ant-input {
    border: 0.25vw solid #5bc1a1;
    border-radius: 4vw;
  }
  .ant-input:hover {
    border: 0.25vw solid #5bc1a1;
  }
  .submitOfferBtn {
    border-radius: 4vw;
    font-size: 3.5vw;
  }
  .single-product {
    border: 0.25vw solid #5bc1a1;
  }
  .ant-form-item-label > label {
    font-size: 3vw !important;
  }
  .ant-upload-list-item {
    border: 0.25vw solid #5bc1a1 !important;
  }
}
@media all and (max-width: 375px) {
  .infoAboutOffersGen {
    padding-left: 18.167vw;
  }
  .ant-radio-group > label {
    font-size: 3.2vw;
  }
}
