.mainSiteWrapper,
.timersWrapper {
  display: flex;
  flex-direction: column;
}
.mainSiteWrapper {
  margin: 0 1.694vw;
  padding: 2.64vh 0;
  min-height: 100vh;
  justify-content: space-around;
}

.upperHalf,
.links,
.bottomHalf {
  display: flex;
  flex-direction: row;
}
.upperHalf {
  justify-content: space-between;
}
.telegramPhotoHolder {
  border-radius: 1.736vw;
  width: 30.556vw;
  background-color: #d9d9d9;
}
.calendarWrapper {
  width: 37.847vw;
  border-radius: 1.042vw;
  border: 0.1vw solid #4f5b75;
  display: inherit;
  flex-direction: row;
}
.cleaningDuty {
  display: inherit;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: -4.4vh;
  color: #0d1e42;
  font-family: "Barlow";
  font-size: 1vw;
  font-weight: 700;
}
.section {
  margin-top: 2.2vh;
  font-family: "Clash Display";
  font-size: 1.806vw;
  font-weight: 600;
  background: linear-gradient(to right, #0e1d42, rgba(255, 60, 0, 0.7));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
  padding: 0 0.347vw;
  text-align: center;
}
.calendar {
  width: 24.653vw;
  display: flex;
  flex-direction: column;
  background-color: #4f5b75;
  padding: 1.87vh 1.389vw;
  border-radius: inherit;
  /* //comment below if calendar ugly */
  min-width: 280px;
  position: relative;
}
.calendarGraphicPlacement {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 4.236vw;
  height: auto;
}
.mirrored {
  top: 0;
  left: 0;
  transform: scale(-1, -1);
}
.links {
  /* margin-top: 5.611vh; */
  justify-content: space-between;
  align-items: center;
}
.mainLinksBtn.section {
  margin-top: unset;
  padding: unset;
  width: 24.653vw;
  padding: 1.32vh 0;
  /* aspect-ratio: 355/58; */
  border: 0.09vw solid #5f6e90;
  border-radius: 1.042vw;
  position: relative;
  cursor: pointer;

  background: linear-gradient(
    to right,
    #0e1d42 0%,
    rgba(255, 60, 0, 0.7) 50%,
    #0e1d42 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-size: 250% 100%; /* Increased for a longer gradient */
}

@keyframes slideGradient {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -100% 0;
  }
}

.mainLinksBtn.section:hover {
  animation: slideGradient 3s linear infinite;
}

.linkBtnPlacement {
  position: absolute;
  right: 0;
  bottom: 0.55vh;
  width: 3.472vw;
  height: auto;
}
.bolid360 {
  flex: auto;
  margin-left: 7vw;
}
.v360-menu-btns {
  display: none;
  padding: unset;
}
.v360-image-container,
.v360-viewport {
  border-radius: 1.042vw;
  height: 38.504vh;
  /* width: auto; */
}
.timersWrapper {
  gap: 3.85vh;
  justify-content: flex-end;
  margin-bottom: 1.8vh;
  margin-left: 0.88vh;
}
.timer {
  width: 34.722vw;
  /* aspect-ratio: 45/8; */
  height: 7.701vh;
  background: unset;
  border: 0.1vw solid #5bc1a1;
  border-radius: 1.042vw;
  justify-content: center;
  align-items: center;
  display: flex;
}
.translated {
  transform: translate(-0.89vh, 0.36vw);
  background-color: #e8e8eb;
}
.ant-card-small {
  border: unset !important;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-card-body {
  padding: unset !important;
}
.ant-statistic {
  display: flex;
}
.timersWrapper .ant-statistic-title {
  font-size: 1.389vw !important;
}
.ant-statistic-title {
  font-family: "Clash Display" !important;
  font-weight: 500 !important;
  color: #0e1d42 !important;
  margin-bottom: unset !important;
  margin-top: 0.33vh;
  display: flex;
  align-items: center;
  margin-right: 1.389vw;
}
.timersWrapper .ant-statistic-content {
  font-size: 1.806vw !important;
}
.ant-statistic-content {
  font-family: "Clash Display" !important;
  font-weight: 600;
  background: linear-gradient(to right, #0e1d42, #5bc1a1);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  display: inline;
}
.secretBtnPlacement {
  display: flex;
  flex-direction: row-reverse;
  /* if bad workee then delete */
  position: absolute;
  bottom: 1vh;
  right: 2.847vw;
}
.secretBtn {
  border-radius: 0.694vw;
  color: #ff3c00;
  background-color: #edc6bc;
  width: 4.861vw;
  aspect-ratio: 14/5;
  border: none;
  cursor: pointer;
  font-size: 0.833vw;
  font-family: "Barlow";
}

.ant-picker-calendar,
.ant-select-selector,
.ant-select-selection-search {
  background: #ffffff !important;
  border: none !important;
}
.ant-picker-calendar {
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  flex: 1;
}
.ant-picker-body {
  background: #fff !important;
  border-radius: 1.042vw !important;
}
.ant-picker-content th {
  color: #4f5b75 !important;
}
.ant-picker-content {
  height: inherit !important;
}
.ant-picker-cell-in-view,
.ant-select-selection-item {
  color: #0e1d42 !important;
}
.ant-picker-cell-inner {
  font-weight: 600;
}
.ant-picker-cell-selected .ant-picker-cell-inner {
  background: rgba(255, 60, 0, 0.45) !important;
  border-radius: 50% !important;
}
.ant-picker-cell-selected .ant-picker-calendar-date-value {
  color: #ff3c00;
}
.ant-picker-calendar-date-today::before {
  border: 1px solid #ff3c00 !important;
  border-radius: 50% !important;
}
.ant-picker-panel {
  background: #fff !important;
  border-top: unset !important;
  padding: 0 1.389vw;
  border-radius: 0 0 1.042vw 1.042vw !important;
}
.calendarHeader {
  padding: 1.042vw 1.389vw;
}
.displayMonth {
  color: #ef4123;
  font-weight: 700;
  font-size: 16px;
}
.changeMonthBtn {
  background-color: #fff;
  width: 2.014vw;
  height: 2.014vw;
  border: 0.1vw solid #b3b3b3;
  border-radius: 0.556vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.simpleGraphic {
  display: none;
}

@media all and (max-width: 3840px) and (min-width: 2800px) {
  .mainSiteWrapper {
    margin: 0 0.859vw;
  }
  .telegramPhotoHolder {
    border-radius: 0.814vw;
    width: 17.188vw;
  }
  .calendarWrapper {
    width: 21.289vw;
    border-radius: 0.391vw;
    border: 0.037vw solid #4f5b75;
  }
  .cleaningDuty {
    font-size: 0.563vw;
  }
  .section {
    font-size: 0.764vw;
    padding: 0 0.13vw;
  }
  .calendar {
    width: 13.867vw;
    padding: 1.87vh 0.625vw;
  }
  .calendarGraphicPlacement {
    width: 1.589vw;
  }
  .mainLinksBtn.section {
    width: 11.094vw;
    border: 0.034vw solid #5f6e90;
    border-radius: 0.391vw;
  }
  .linkBtnPlacement {
    width: 1.302vw;
  }
  .bolid360 {
    margin-left: 2.604vw;
  }
  .v360-image-container,
  .v360-viewport {
    border-radius: 0.391vw;
  }
  .timer {
    width: 14.063vw;
    border: 0.052vw solid #5bc1a1;
    border-radius: 0.391vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 0.521vw !important;
    margin-right: 0.521vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 0.677vw !important;
  }
  .secretBtn {
    border-radius: 0.26vw;
    width: 1.823vw;
    font-size: 0.313vw;
  }
  .ant-picker-body {
    border-radius: 0.391vw !important;
  }
  .ant-picker-panel {
    padding: 0 0.521vw;
    border-radius: 0 0 0.391vw 0.391vw !important;
  }
  .calendarHeader {
    padding: 0.391vw 0.521vw;
  }
  .changeMonthBtn {
    width: 0.755vw;
    height: 0.755vw;
    border: 0.037vw solid #b3b3b3;
    border-radius: 0.208vw !important;
  }
}
@media all and (max-width: 2560px) and (min-width: 1921px) {
  .mainSiteWrapper {
    margin: 0 1.289vw;
  }
  .telegramPhotoHolder {
    border-radius: 1.085vw;
    width: 22.917vw;
  }
  .calendarWrapper {
    width: 28.386vw;
    border-radius: 0.586vw;
    border: 0.056vw solid #4f5b75;
  }
  .cleaningDuty {
    font-size: 0.75vw;
  }
  .section {
    font-size: 1.146vw;
    padding: 0 0.195vw;
  }
  .calendar {
    width: 18.49vw;
    padding: 1.87vh 0.938vw;
  }
  .calendarGraphicPlacement {
    width: 2.383vw;
  }
  .mainLinksBtn.section {
    width: 16.641vw;
    border: 0.051vw solid #5f6e90;
    border-radius: 0.586vw;
  }
  .linkBtnPlacement {
    width: 1.953vw;
  }
  .bolid360 {
    margin-left: 3.906vw;
  }
  .v360-image-container,
  .v360-viewport {
    border-radius: 0.586vw;
  }
  .timer {
    width: 21.094vw;
    border: 0.078vw solid #5bc1a1;
    border-radius: 0.586vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 0.781vw !important;
    margin-right: 0.781vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 1.016vw !important;
  }
  .secretBtn {
    border-radius: 0.391vw;
    width: 2.734vw;
    font-size: 0.469vw;
  }
  .ant-picker-body {
    border-radius: 0.586vw !important;
  }
  .ant-picker-panel {
    padding: 0 0.781vw;
    border-radius: 0 0 0.586vw 0.586vw !important;
  }
  .calendarHeader {
    padding: 0.586vw 0.781vw;
  }
  .changeMonthBtn {
    width: 1.133vw;
    height: 1.133vw;
    border: 0.056vw solid #b3b3b3;
    border-radius: 0.313vw !important;
  }
}
@media all and (max-width: 1920px) and (min-width: 1500px) {
  .mainSiteWrapper {
    margin: 0 1.736vw;
  }
  .telegramPhotoHolder {
    /*set for 1600px width */
    border-radius: 1.302vw;
    width: 27.5vw;
  }
  .calendarWrapper {
    /*set for 1600px width */
    width: 34.063vw;
    border-radius: 0.781vw;
    border: 0.075vw solid #4f5b75;
  }
  .cleaningDuty {
    /*set for 1600px width */
    font-size: 0.9vw;
  }
  .section {
    font-size: 1.528vw;
    padding: 0 0.26vw;
  }
  .calendar {
    /*set for 1600px width */
    width: 22.188vw;
    padding: 1.87vh 1.25vw;
  }
  .calendarGraphicPlacement {
    width: 3.177vw;
  }
  .mainLinksBtn.section {
    width: 22.188vw;
    border: 0.068vw solid #5f6e90;
    border-radius: 0.781vw;
  }
  .linkBtnPlacement {
    width: 2.604vw;
  }
  .bolid360 {
    margin-left: 5.208vw;
  }
  .v360-image-container,
  .v360-viewport {
    border-radius: 0.781vw;
  }
  .timer {
    width: 28.125vw;
    border: 0.104vw solid #5bc1a1;
    border-radius: 0.781vw;
  }
  .translated {
    transform: translate(-0.89vh, 0.26vw);
  }
  .timersWrapper .ant-statistic-title {
    font-size: 1.042vw !important;
    margin-right: 1.042vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 1.354vw !important;
  }
  .secretBtn {
    border-radius: 0.521vw;
    width: 3.646vw;
    font-size: 0.625vw;
  }
  .ant-picker-body {
    border-radius: 0.781vw !important;
  }
  .ant-picker-panel {
    padding: 0 1.042vw;
    border-radius: 0 0 0.781vw 0.781vw !important;
  }
  .calendarHeader {
    padding: 0.781vw 1.042vw;
  }
  .changeMonthBtn {
    width: 1.51vw;
    height: 1.51vw;
    border: 0.075vw solid #b3b3b3;
    border-radius: 0.417vw !important;
  }
}
@media all and (max-width: 1280px) {
  .calendar {
    min-width: 315px;
  }
  .section {
    padding: 0 1vw;
  }
  .calendarWrapper {
    width: unset;
  }
  .mainLinksBtn.section {
    width: unset;
    padding: 1.32vh 3.125vw;
  }
  .upperHalf {
    gap: 4vw;
  }
  .timersWrapper {
    margin-left: 0.46vh;
  }
  .bolid360 {
    margin-left: 4vw;
  }
  .secretBtnPlacement {
    display: none;
  }
  .timer {
    width: 39.063vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 1.563vw !important;
    margin-right: 1.563vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 2.031vw !important;
  }
}
@media all and (max-width: 1024px) {
  .cleaningDuty {
    font-size: 1.237vw;
  }
  .section {
    font-size: 1.848vw;
  }
  .cleaningDuty .section {
    font-size: 2.1vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 1.553vw !important;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 2.339vw !important;
  }
  .upperHalf {
    flex-direction: row-reverse;
  }
  .telegramPhotoHolder {
    display: none;
  }
  .bolid360 {
    display: none;
  }
  .timer {
    width: 43.828vw;
  }
  .changeMonthBtn {
    width: 2.832vw;
    height: 2.832vw;
    border: 0.141vw solid #b3b3b3;
    border-radius: 0.781vw !important;
  }
  .calendarGraphicPlacement {
    width: 5.957vw;
  }
  .calendar {
    padding: 1.87vh 1.922vw;
  }
}
@media all and (max-width: 900px) {
  .mainSiteWrapper {
    gap: 4vh;
  }
  .upperHalf {
    order: 2;
    justify-content: center;
    align-items: center;
    gap: 0;
  }
  .calendarWrapper {
    flex-direction: column-reverse;
    width: 43.828vw;
    border-radius: 1.563vw;
  }
  .calendar {
    width: unset;
  }
  .cleaningDuty {
    margin: 2vh 0;
    flex-direction: row;
    font-size: 1.667vw;
  }
  .cleaningDuty .section {
    margin-top: 0;
    font-size: 2.8vw;
  }
  .links,
  .bottomHalf,
  .upperHalf {
    flex-direction: column;
  }
  .timersWrapper {
    gap: 2.5vh;
  }
  .links {
    order: 1;
    gap: 2.5vh;
    margin-top: 2.64vh;
  }
  .bottomHalf {
    order: 3;
  }
  .mainLinksBtn.section {
    width: 43.828vw;
    font-size: 2.4vw;
    border-radius: 1.563vw;
  }
  .timer {
    border-radius: 1.563vw;
  }
  .linkBtnPlacement {
    width: 6vw;
  }
  .translated {
    transform: translate(-0.49vh, 0.62vw);
  }
  .timersWrapper .ant-statistic-title {
    font-size: 1.8vw !important;
  }
  .simpleGraphic {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: 1vh;
  }
  .wroomEffect {
    margin-bottom: 3px;
  }
}
@media all and (max-width: 768px) {
  .cleaningDuty {
    font-size: 2.193vw;
  }
  .section {
    font-size: 3.2vw;
    padding: 0 3vw;
  }
  .cleaningDuty .section {
    font-size: 3.2vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 2.404vw !important;
    margin-right: 2.404vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 3.185vw !important;
  }
  .timer {
    width: 60.437vw;
    border-radius: 1.953vw;
  }
  .linkBtnPlacement {
    width: 7vw;
  }
  .changeMonthBtn {
    width: 3.776vw;
    height: 3.776vw;
    border: 0.188vw solid #b3b3b3;
    border-radius: 1.042vw !important;
  }
  .calendarWrapper {
    width: 60.437vw;
    border-radius: 1.953vw;
  }
  .mainLinksBtn.section {
    width: 60.437vw;
    font-size: 3.2vw;
    border-radius: 1.953vw;
  }
  .calendarGraphicPlacement {
    width: 9.043vw;
  }
  .calendar {
    padding: 2.563vw 2.563vw;
  }
}
@media all and (max-width: 524px) {
  .cleaningDuty {
    font-size: 3.214vw;
  }
  .section {
    font-size: 4.69vw;
    padding: 0 4.397vw;
  }
  .cleaningDuty .section {
    font-size: 4.69vw;
  }
  .timersWrapper .ant-statistic-title {
    font-size: 3.523vw !important;
    margin-right: 3.523vw;
  }
  .timersWrapper .ant-statistic-content {
    font-size: 4.668vw !important;
  }
  .timer {
    width: 88.579vw;
    border-radius: 2.863vw;
  }
  .linkBtnPlacement {
    width: 10.26vw;
  }
  .changeMonthBtn {
    width: 5.534vw;
    height: 5.534vw;
    border: 0.276vw solid #b3b3b3;
    border-radius: 1.527vw !important;
  }
  .calendarWrapper {
    width: 88.579vw;
    border-radius: 2.863vw;
  }
  .mainLinksBtn.section {
    width: 88.579vw;
    font-size: 6.874vw;
    border-radius: 2.863vw;
  }
  .calendarGraphicPlacement {
    width: 13.254vw;
  }
  .calendar {
    padding: 3.756vw 3.756vw;
  }
  .simpleGraphicBolid {
    width: 115px;
    aspect-ratio: 2/1;
  }
  .links {
    margin-top: 70px;
  }
  .upperHalf,
  .bottomHalf {
    z-index: 10;
  }
}
